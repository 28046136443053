import React from 'react';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination } from '@apollo/client/utilities';
import { setupStore } from 'store';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import DesignSystem from 'pages/DesignSystem/DesignSystem';
import Setup from 'pages/Onboarding/Setup';
import RuleContainer from 'pages/Rule/RuleContainer';
import HistoryContainer from 'pages/History/HistoryContainer';
import HistoryDetailContainer from 'pages/History/HistoryDetailContainer';
import Settings from 'pages/Settings/Settings';
import NotFound from 'pages/Errors/NotFound';
import ResetPassword from 'pages/Auth/ResetPassword';
import Templates from 'pages/Templates/Templates';
import PreviewPage from 'pages/Preview/PreviewPage';
import ArchivedRulesPage from 'pages/Dashboard/Archived/ArchivedRulesPage';
import RulesPage from 'pages/Dashboard/Rules/RulesPage';
import SpendTargetsPage from 'pages/SpendTargets/SpendTargetsPage';
import CampaignMappingPage from 'pages/CampaignMapping/CampaignMappingPage';
import ChangePlan from 'pages/Subscribe/ChangePlan';
import Account from 'pages/Account/Account';
import { RawAxiosRequestHeaders } from 'axios';
import mixpanel from 'mixpanel-browser';

// Initialize Mixpanel
const mixpanelProdToken = '0d296c889d8af865624f3fdad67c2a8b';
const mixpanelDevToken = 'b7ba156698c7ae2f843ca4ee4c7c092e';
const isProd = window.location.hostname.includes('meetfern.ai');
const mixpanelToken = isProd ? mixpanelProdToken : mixpanelDevToken;

mixpanel.init(mixpanelToken, {
  debug: !isProd,
  track_pageview: true,
  persistence: 'localStorage',
});

Sentry.init({
  dsn: 'https://94cfeb8bc4a84dff81d8d9ff90cd450e@o4504650755866624.ingest.sentry.io/4504650758750208',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false }),
  ],
  tracesSampleRate: 1.0,
  debug: process.env.NODE_ENV !== 'production',
  environment: window.location.hostname,
  autoSessionTracking: true,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const httpLink = createHttpLink({
  uri: process.env['REACT_APP_APOLLO_SERVER_URL'],
});

const authLink = setContext((_, context) => {
  const token = localStorage.getItem('token');
  const headers = context['headers'] as RawAxiosRequestHeaders;

  return {
    headers: {
      ...headers,
      Authorization: token ?? '',
    },
  };
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      keyFields: [],
      fields: {
        runs: relayStylePagination(),
      },
    },
  },
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
  },
});

export const appRouter = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index element={<RulesPage />} />
      <Route path="/:folderId" element={<RulesPage />} />
      <Route path="archived" element={<ArchivedRulesPage />} />
      <Route path="setup" element={<Setup />} />
      <Route path="design-system" element={<DesignSystem />} />
      <Route path="rules/new" element={<RuleContainer />} />
      <Route path="rules/:ruleId" element={<RuleContainer />} />
      <Route path="rules/:ruleId/preview" element={<PreviewPage />} />
      <Route path="history" element={<HistoryContainer />} />
      <Route path="history/:ruleId" element={<HistoryContainer />} />
      <Route
        path="history/:ruleId/run/:runId"
        element={<HistoryDetailContainer />}
      />
      <Route path="settings" element={<Settings />} />
      <Route path="settings/integrations" element={<Settings />} />
      <Route path="settings/selling-partner-accounts" element={<Settings />} />
      <Route path="settings/:brandId" element={<Settings />} />
      <Route path="change-password" element={<ResetPassword />} />
      <Route path="templates" element={<Templates />} />
      <Route path="spend-targets" element={<SpendTargetsPage />} />
      <Route path="campaign-mapping" element={<CampaignMappingPage />} />
      <Route path="change-plan/:plan" element={<ChangePlan />} />
      <Route path="account" element={<Account />} />
      <Route path="*" element={<NotFound />} />
    </Route>,
  ),
);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={setupStore()}>
        <RouterProvider router={appRouter} />
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
