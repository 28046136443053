import { Link, useMatch, useParams } from 'react-router-dom';
import MenuItem from './MenuItem';
import SharedWorkspaceTab from 'components/Menu/SharedWorkspaceTab';
import UserDetail from './UserDetail/UserDetail';
import ReversedLogo from 'components/Images/ReversedLogo';
import {
  GoalsIcon,
  TemplateIcon,
  TestingBanner,
  HistoryIcon,
  RuleIcon,
  MappingIcon,
  ChartIcon,
} from 'components/Icons';
import useMenu from './useMenu';
import { FEATURES } from 'components/Permissions/PermissionsGate';
import { getSvgFillActiveStyle, getSvgStrokeActiveStyle } from './helpers';

export function Menu(): JSX.Element {
  const { userEnv, bannerOpen } = useMenu();

  const isHistoryPage = useMatch('/history/*');
  const ruleLandingPage = useMatch('/');
  const rulePage = useMatch('/rules/*');
  const spendTargetsPage = useMatch('/spend-targets/*');
  const isArchivedPage = useMatch('/archived');
  const isTemplatesPage = useMatch('/templates');
  const isCampaignMappingPage = useMatch('/campaign-mapping');

  const { folderId } = useParams();
  const isRulePage = ruleLandingPage || rulePage || isArchivedPage || folderId;

  return (
    <aside
      className={`fixed inset-y-0 z-40 flex h-screen w-[14.75rem] flex-col ${bannerOpen && 'mt-[3.063rem] h-full'}
    `}
    >
      <div className="flex min-h-0 flex-1 flex-col bg-neutral-100">
        <span className="absolute left-[146px]">
          {userEnv === 'test' && <TestingBanner colour="#FF9D66" />}
          {userEnv === 'development' && <TestingBanner colour="#28C585" />}
        </span>
        <div className="flex flex-1 flex-col overflow-y-auto">
          {' '}
          <Link to="/">
            <div className="mb-6 ml-6 mt-8">
              <ReversedLogo />
            </div>
          </Link>
          <nav className="flex flex-1 flex-col bg-neutral-100">
            <div>
              <div className="text-neutral-60 text-xs pl-6 pb-2 mt-3 tracking-tighter">
                AUTOMATIONS
              </div>
              <MenuItem
                label={'Rules'}
                isActive={!!isRulePage}
                link="/"
                icon={
                  <RuleIcon stroke={getSvgStrokeActiveStyle(!!isRulePage)} />
                }
              />
              <MenuItem
                label={'Templates'}
                isActive={!!isTemplatesPage}
                link="/templates"
                icon={
                  <TemplateIcon
                    fill={getSvgFillActiveStyle(!!isTemplatesPage)}
                  />
                }
              />
              <MenuItem
                label={'Rule history'}
                isActive={!!isHistoryPage}
                link="/history"
                icon={
                  <HistoryIcon
                    stroke={getSvgStrokeActiveStyle(!!isHistoryPage)}
                  />
                }
              />
            </div>
            <div className="mt-6">
              <div className="text-neutral-60 text-xs pl-6 pb-2 tracking-tighter">
                STRATEGY
              </div>
              <MenuItem
                label={'Spend targets'}
                feature={FEATURES.spendTargets}
                gatedFeature
                isActive={!!spendTargetsPage}
                link="/spend-targets"
                icon={
                  <GoalsIcon
                    stroke={getSvgStrokeActiveStyle(!!spendTargetsPage)}
                  />
                }
              />
              <MenuItem
                label={'Campaign mapping'}
                feature={FEATURES.campaignMapping}
                isActive={!!isCampaignMappingPage}
                gatedFeature
                link="/campaign-mapping"
                icon={
                  <MappingIcon
                    fill={getSvgFillActiveStyle(!!isCampaignMappingPage)}
                  />
                }
              />
            </div>
            <div className="mt-6">
              <div className="text-neutral-60 text-xs pl-6 pb-2 tracking-tighter">
                PERFORMANCE
              </div>
              <MenuItem
                label={'Reporting'}
                feature={FEATURES.reporting}
                link={`${process.env['REACT_APP_BETA_URL']}/reports`}
                isActive={false}
                icon={<ChartIcon stroke={getSvgStrokeActiveStyle(false)} />}
              />
            </div>
            <div
              className={`flex h-full flex-col justify-end mt-6 pb-[1.75rem] ${bannerOpen && 'pb-[4.813rem]'}`}
            >
              <SharedWorkspaceTab />
              <div>
                <UserDetail />
              </div>
            </div>
          </nav>
        </div>
      </div>
    </aside>
  );
}
