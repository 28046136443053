import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import Pill, { PillStyle } from 'design-system/components/Pill/Pill';
import { AccountType, Marketplace } from 'codegen/graphql';
import Spinner from 'ui/Spinner/Spinner';

enum MarketplaceRetailDataStatus {
  CONNECTED = 'Connected',
  NOT_CONNECTED = 'Not connected',
  NOT_CONNECTED_TO_ADS_DATA = 'Requires ads data',
  NOT_AVAILABLE = 'Not available',
}

type Props = {
  marketplace: Marketplace;
  brandAccountType: AccountType;
  sellingPartnerAuthRedirectRunning: boolean;
};

export default function MarketplaceRetailData({
  marketplace,
  brandAccountType,
  sellingPartnerAuthRedirectRunning,
}: Props): JSX.Element | null {
  const {
    sellingPartnerApiOAuthURL,
    isConnectedForAdsData,
    isConnectedForRetailData,
  } = marketplace;

  const showRetailDataConnectButton =
    isConnectedForAdsData &&
    sellingPartnerApiOAuthURL != null &&
    !isConnectedForRetailData;

  function retailDataStatus(): MarketplaceRetailDataStatus {
    if (brandAccountType === AccountType.Vendor) {
      return MarketplaceRetailDataStatus.NOT_AVAILABLE;
    }

    if (!isConnectedForAdsData) {
      return MarketplaceRetailDataStatus.NOT_CONNECTED_TO_ADS_DATA;
    }

    if (isConnectedForRetailData) {
      return MarketplaceRetailDataStatus.CONNECTED;
    }

    return MarketplaceRetailDataStatus.NOT_CONNECTED;
  }

  function retailDataPillStyle(): PillStyle {
    if (isConnectedForRetailData) {
      return PillStyle.PRIMARY;
    }

    return PillStyle.NEUTRAL;
  }

  return (
    <div>
      <div className="text-xs text-neutral-80 mb-0.5">Retail data</div>
      <div className="h-8 flex flex-col justify-center">
        {showRetailDataConnectButton ? (
          <PrimaryButton
            disabled={sellingPartnerAuthRedirectRunning}
            width="w-[6.25rem]"
            size="sm"
            onClick={() => {
              window.location.href = sellingPartnerApiOAuthURL;
            }}
          >
            {sellingPartnerAuthRedirectRunning ? (
              <span className="flex justify-center">
                <Spinner />
              </span>
            ) : (
              <>Connect</>
            )}
          </PrimaryButton>
        ) : (
          <Pill label={retailDataStatus()} style={retailDataPillStyle()} />
        )}
      </div>
    </div>
  );
}
